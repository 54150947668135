import React from "react";
// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import BookTable from "./pages/BookTable";
import EastleaMenu from "./pages/menu/EastleaMenu";
import Service from "./pages/Service";
import NotFound from "./pages/NotFound";
import Footer from "./components/Footer";
import MashwedeMenu from "./pages/menu/MashwedeMenu";
import { MenuQr } from "./components/MenuQr";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/book-table" element={<BookTable />} />
        <Route path="/eastlea-menu" element={<EastleaMenu />} />
        <Route path="/mashwede-menu" element={<MashwedeMenu />} />
        <Route path="/service" element={<Service />} />
        <Route path="/menu-qr" element={<MenuQr />}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

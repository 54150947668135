import React from 'react'
import { Button, Nav , Image} from 'react-bootstrap'
import logo from './garwe-logo.png';

export default function Navbar() {
  return (
    <Nav className='navbar navbar-expand-lg navbar-light bg-light px-4 px-lg-5 py-3 py-lg-0 fixed-top'>
          <Nav.Link className='navbar-brand p-0' href='#'>
            <Image src={logo} className='m-0' height={70}/>
            {/* <h1 className="text-primary m-0"><i className="fa fa-utensils me-3"></i>{{ logo }}</h1> */}
          </Nav.Link>
          <Button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="fa fa-bars"></span>
          </Button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0 pe-4">
              <Nav.Link href="/" className="nav-item nav-link active">Home</Nav.Link>
              <Nav.Link href="/about" className="nav-item nav-link">About</Nav.Link>
              {/* <Nav.Link href="/service" className="nav-item nav-link">Service</Nav.Link> */}
              {/* <Nav.Link href="/menu" className="nav-item nav-link">Menu</Nav.Link> */}
              <div className="nav-item dropdown">
                <Nav.Link href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Menu</Nav.Link>
                <div className="dropdown-menu m-0">
                  <Nav.Link href="/eastlea-menu" className="dropdown-item">Eastlea</Nav.Link>
                  <Nav.Link href="/mashwede-menu" className="dropdown-item">Mashwede High Glen</Nav.Link>
                </div>
              </div>
              {/* <div className="nav-item dropdown">
                <Nav.Link href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Branches</Nav.Link>
                <div className="dropdown-menu m-0">
                  <Nav.Link href="/#" className="dropdown-item">Eastlea</Nav.Link>
                  <Nav.Link href="/#" className="dropdown-item">Machipisa</Nav.Link>
                </div>
              </div> */}
              <Nav.Link href="/contact" className="nav-item nav-link">Contact</Nav.Link>
            </div>
            <a href="/#book-table" className="btn btn-garwe py-2 px-4">Book A Table</a>
          </div>
        </Nav>
  )
}

import React from "react";
import { Image } from "react-bootstrap";

interface Props {
  image: string,
  name: string,
  description: string,
  price: number,
}

export default function MenuItem(props: Props) {
  return (
    <div className="col-lg-6">
      <div className="d-flex align-items-center">
        <Image
          className="flex-shrink-0 img-fluid rounded"
          src={props.image}
          alt=""
          style={{ width: "80px;" }}
        />
        <div className="w-100 d-flex flex-column text-start ps-4">
          <h5 className="d-flex justify-content-between border-bottom pb-2">
            <span>{props.name}</span>
            <span className="text-garwe">${props.price}</span>
          </h5>
          <small className="fst-italic">{props.description}</small>
        </div>
      </div>
    </div>
  );
}

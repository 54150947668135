import { Image } from "react-bootstrap";

interface Props {
  name: string;
  designation: string;
}

export const Chef = (props: Props) => {
  return (
    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div className="team-item text-center rounded overflow-hidden">
        <div className="rounded-circle overflow-hidden m-4">
          <Image className="img-fluid" src="img/team-1.jpg" alt="" />
        </div>
        <h5 className="mb-0">{props.name}</h5>
        <small>{props.designation}</small>
        <div className="d-flex justify-content-center mt-3">
          {/* <a className="btn btn-square btn-garwe mx-1" href="/#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-garwe mx-1" href="/#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-garwe mx-1" href="/#"><i className="fab fa-instagram"></i></a> */}
        </div>
      </div>
    </div>
  );
};

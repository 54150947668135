const startersWestern = [
  {
    image: "",
    name: "Chicken Livers",
    description: "",
    price: 2,
  },
];
const mains = [
  {
    image: "",
    name: "1/4 Chicken & Chips",
    description: "",
    price: 6,
  },
  {
    image: "",
    name: "BBQ Wings & Chips",
    description: "",
    price: 7,
  },
  {
    image: "",
    name: "Burger & Chips",
    description: "",
    price: 8,
  },
  {
    image: "",
    name: "400g Pork Chops & Chips",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "1/2 Chicken & Chips",
    description: "",
    price: 12,
  },
  {
    image: "",
    name: "T-Bone & Chips",
    description: "",
    price: 12,
  },
  {
    image: "",
    name: "Grilled Fish (1000g) & Chips",
    description: "",
    price: 15,
  },
  {
    image: "",
    name: "Beef Stir Fry",
    description: "",
    price: 15,
  },
];
const salads = [
  {
    image: "",
    name: "Bean Salad",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Coleslaw",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Traditional Garden Salad",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Chicken Salad",
    description: "",
    price: 6,
  },
  {
    image: "",
    name: "Chicken Coleslaw Salad",
    description: "",
    price: 6,
  },
];

const classicCombos = [
  {
    image: "",
    name: "T-Bone & 1/4 Chicken",
    description: "",
    price: 18,
  },
  {
    image: "",
    name: "Pork Chops & 1/4 Chicken",
    description: "",
    price: 18,
  },
  {
    image: "",
    name: "Braai Pack, Chips & Salad",
    description: "1/4 Chicken, sausage & pork chops/T-Bone",
    price: 20,
  },
];


const extras = [
  {
    image: "",
    name: "Plain Rice",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Plain Chips",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Potato Wedges",
    description: "",
    price: 2,
  },
];

const desserts = [
  {
    image: "",
    name: "Ice Cream",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Fruit Platter",
    description: "Small",
    price: 3,
  },
  {
    image: "",
    name: "Fruit Platter",
    description: "Medium",
    price: 5,
  },
  {
    image: "",
    name: "Cake Slice",
    description: "",
    price: 5,
  },
];

export {
  startersWestern,
  mains,
  salads,
  classicCombos,
  extras,
  desserts
};

let traditionalBreakFast = [
  {
    image: "",
    name: "Monday - Tea ne mbambaira/Madhumbe",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Tuesday - Tea nemutakura",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Wednesday - Tea ne Chimodho",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Thursday - Tea ne Manhuchu",
    description: "",
    price: 2,
  },
  {
    image: "",
        name:"Friday - Tea ne Mutakura",
        description:"",
        price:3
  },
];
let westernBreakfast = [
  {
    image: "",
    name: "Tomato Sandwich",
    description: "",
    price: 0.99,
  },
  {
    image: "",
    name: "Egg & Avo",
    description: "",
    price: 1.99,
  },
  {
    image: "",
    name: "French Toast",
    description: "",
    price: 1.99,
  },
  {
    image: "",
    name: "Ham & Cheese Sandwich",
    description: "",
    price: 2.99,
  },
  {
    image: "",
    name: "Omelette",
    description: "",
    price: 2.99,
  },
];
let extras = [
  {
    image: "",
    name: "Roast Potatoes",
    description: "",
    price: 1.99,
  },
  {
    image: "",
    name: "Toast, butter & Jam",
    description: "",
    price: 1.99,
  },
  {
    image: "",
    name: "Cake Slice",
    description: "",
    price: 2.99,
  },
];
export  {traditionalBreakFast, westernBreakfast, extras};

import MenuItem from "./MenuItem";

interface Props {
  menuName: string;
  menuItems: {
    image: string;
    name?: string;
    description?: string;
    price?: number;
  }[];
}

export default function MenuTab(props: Props) {
  return (
      <div className="row g-4">
        <h3 className="text-uppercase text-garwe">{props.menuName}</h3>
        {props.menuItems.map((item, index) => (
          <MenuItem
            key={index}
            image={item.image}
            name={item.name || ""}
            description={item.description || ""}
            price={item.price || 0}
          />
        ))}
      </div>
  );
}

const starters = [
  {
    image: "",
    name: "Nzungu",
    description: "",
    price: 1,
  },
  {
    image: "",
    name: "Pumpkin Seeds",
    description: "",
    price: 1,
  },
  {
    image: "",
    name: "Chilli Chicken Feet",
    description: "",
    price: 1,
  },
  {
    image: "",
    name: "Chilli Chicken Livers",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Madora",
    description: "",
    price: 3,
  },
  
];
const starches = [
  {
    image: "",
    name: "Zviyo",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Mhunga",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Rice Dovi",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Mupunga Dovi",
    description: "",
    price: 4,
  },
];
const vegetables = [
  {
    image: "",
    name: "Mutsine",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Munyevhe",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Tsunga Dovi",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Derere",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Muboora (Seasonal)",
    description: "",
    price: 2,
  },
];


const meats = [
  {
    image: "",
    name: "Gango",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Braai Pack",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Gochie Gochie",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Hanga",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Road Runner",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Goat",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Tsuro",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Ox-Tail",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Biltong",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Brisket",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Cow Head",
    description: "Ask for availability",
    price: 4,
  },
  {
    image: "",
    name: "Goat Head",
    description: "Ask for availability",
    price: 3,
  },
  {
    image: "",
    name: "Zondo",
    description: "Ask for availability",
    price: 3,
  },
  {
    image: "",
    name: "Haifiridzi",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Beef Bones",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Maguru",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Pork Boness",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Knuckle Bones",
    description: "",
    price: 3,
  },
];

const desserts = [
  {
    image: "",
    name: "Fruit Platter",
    description: "",
    price: 4,
  },
  {
    image: "",
    name: "Hodzeko",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Fruit Kebab",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Wild Fruits in Season",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Seasonal Fruits",
    description: "",
    price: 1,
  },
];

export {
  starters,
  starches,
  vegetables,
  meats,
  desserts,
};

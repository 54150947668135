const starters = [
  {
    image: "",
    name: "Roasted Nuts",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Hodzeko",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Madora",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Chilli Chicken Livers",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Pumpkin Seeds",
    description: "",
    price: 1,
  },
  {
    image: "",
    name: "Chilli Chicken Feet",
    description: "",
    price: 1,
  },
  {
    image: "",
    name: "Chicken Livers",
    description: "",
    price: 1,
  },
  
];
const starches = [
  {
    image: "",
    name: "Mupunga une Dovi",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Zviyo",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Mhunga",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Rice ne Dovi",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "SAMP",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Mapfunde",
    description: "",
    price: 2,
  },
];
const vegetables = [
  {
    image: "",
    name: "Mutsine",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Munyevhe",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Fresh Munyevhe",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Tsunga une Dovi",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Derere",
    description: "",
    price: 2,
  },
  {
    image: "",
    name: "Muboora (Seasonal)",
    description: "",
    price: 2,
  },
];

const delicacies = [
  {
    image: "",
    name: "Game Meat",
    description: "",
    price: 25,
  },
  {
    image: "",
    name: "Oxtail",
    description: "",
    price: 20,
  },
  {
    image: "",
    name: "Biltong",
    description: "",
    price: 20,
  },
  {
    image: "",
    name: "Rabbit",
    description: "",
    price: 15,
  },
  {
    image: "",
    name: "Kariba Bream",
    description: "",
    price: 15,
  },
  {
    image: "",
    name: "Guinea Fowl (Hanga)",
    description: "",
    price: 15,
  },
  {
    image: "",
    name: "Brisket",
    description: "",
    price: 15,
  },
  {
    image: "",
    name: "Beef Stir Fry",
    description: "",
    price: 15,
  },
  {
    image: "",
    name: "Road Runner",
    description: "",
    price: 12,
  },
  {
    image: "",
    name: "Roasted/Stewed Goat",
    description: "",
    price: 10,
  },
  
];

const offals = [
  {
    image: "",
    name: "Liver & Kidney",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "Zvinyenze",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "Maguru ne Matumbu",
    description: "",
    price: 10,
  },
];

const chigutiro = [
  {
    image: "",
    name: "A Choice of 3 Meats",
    description: "",
    price: 20,
  },
];

const meats = [
  {
    image: "",
    name: "G-Squared Gango",
    description: "",
    price: 12,
  },
  {
    image: "",
    name: "Haifiridzi",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "Beef Bones",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "Pork Bones",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "Pork Trotters",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "Knucle Bones",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "Mazondo",
    description: "",
    price: 10,
  },
  {
    image: "",
    name: "Goat Head",
    description: "Ask for availability",
    price: 10,
  },
  {
    image: "",
    name: "Cow Head",
    description: "Ask for availability",
    price: 10,
  },
];

const desserts = [
  {
    image: "",
    name: "Cake Slice",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Fruit Platter",
    description: "",
    price: 5,
  },
  {
    image: "",
    name: "Hodzeko",
    description: "",
    price: 3,
  },
  {
    image: "",
    name: "Wild Fruits in Season",
    description: "",
    price: 1,
  },
  {
    image: "",
    name: "Seasonal Fruits",
    description: "",
    price: 1,
  },
];

export {
  starters,
  starches,
  vegetables,
  delicacies,
  offals,
  chigutiro,
  meats,
  desserts,
};

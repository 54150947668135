import React from 'react'
import { Image, Container } from 'react-bootstrap';
import hero from './../static/img/hero.png';
import about1 from '../static/img/about-1.jpg';
import about2 from './../static/img/about-2.jpg';
import about3 from './../static/img/about-3.jpg';
import about4 from './../static/img/about-4.jpg';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <Container fluid="true" className='container-xxl bg-white p-0'>
      <Container fluid={true} className="container-xxl position-relative p-0">
        <div className="container-xxl py-5 bg-dark hero-header mb-5">
          <div className="container my-5 py-5">
            <div className="row align-items-center g-5">
              <div className="col-lg-6 text-center text-lg-start">
                <h1 className="display-3 text-white animated slideInLeft">Enjoy Our<br />Delicious Meal</h1>
                <p className="text-white animated slideInLeft mb-4 pb-2">For the unique experience of the African and Western Dishes.</p>
                <Link to="/" className="btn btn-garwe py-sm-3 px-sm-5 me-3 animated slideInLeft">Book A Table</Link>
              </div>
              <div className="col-lg-6 text-center text-lg-end overflow-hidden">
                <Image className="img-fluid" src={hero} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item rounded pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-user-tie text-garwe mb-4"></i>
                  <h5>Master Chefs</h5>
                  <p>Our culinary team is led by renowned chefs with a passion for innovative cuisine.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item rounded pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-utensils text-garwe mb-4"></i>
                  <h5>Quality Food</h5>
                  <p>At Garwe Restaurant, we're passionate about serving exceptional cuisine that exceeds our guests' expectations.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item rounded pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-cart-plus text-garwe mb-4"></i>
                  <h5>Online Order</h5>
                  <p>Place an order in just few clicks and we'll deliver!</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6">
                <div className="row g-3">
                  <div className="col-6 text-start">
                    <Image className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src={about1}/>
                  </div>
                  <div className="col-6 text-start">
                    <Image className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src={about2} style={{marginTop: "25%;"}}/>
                  </div>
                  <div className="col-6 text-end">
                    <Image className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src={about3}/>
                  </div>
                  <div className="col-6 text-end">
                    <Image className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src={about4}/>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h5 className="section-title ff-secondary text-start text-garwe fw-normal">About Us</h5>
                <h3 className="mb-4 text-garwe">Welcome to Garwe Restaurant & Events</h3>
                <p className="mb-4">A Down-To-Earth, Inexpensive Restaurant with a Flavor Of The Old Days that provides services with Warmth and The Taste Of A Mother's Cooking. Each dish is made with loving care and served with genuine smiles. You can enjoy any combination of our dishes according to how you feel day-by-day while staying within a budget and always eating nutritious food.</p>
                <div className="row g-4 mb-4">
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center border-start border-5 border-garwe px-3">
                      <h1 className="flex-shrink-0 display-5 text-garwe mb-0" data-toggle="counter-up">15</h1>
                      <div className="ps-4">
                        <p className="mb-0">Years of</p>
                        <h6 className="text-uppercase mb-0">Experience</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center border-start border-5 border-garwe px-3">
                      <h1 className="flex-shrink-0 display-5 text-garwe mb-0" data-toggle="counter-up">50</h1>
                      <div className="ps-4">
                        <p className="mb-0">Popular</p>
                        <h6 className="text-uppercase mb-0">Master Chefs</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <Link className="btn btn-garwe py-3 px-5 mt-2" to="/">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import MenuTab from "../../components/MenuTab";
import { starters,
  starches,
  vegetables,
  meats,
  desserts, } from "../../components/mashwede/TraditionalMenu";



export default function MashwedeMenu() {
  return (
    <Container fluid="true" className="container-xxl bg-white p-0">
      <div className="container-xxl py-5 bg-dark hero-header mb-5">
        <div className="container text-center my-5 pt-5 pb-4">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Food Menu
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center text-uppercase">
              <li className="breadcrumb-item">
                <Link className="text-garwe" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Menu
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-garwe fw-normal">
              Food Menu
            </h5>
            <h1 className="mb-5">Most Popular Items</h1>
          </div>
          <div
            className="tab-class text-center wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
              <li className="nav-item">
                <a
                  className="d-flex align-items-center text-start mx-3 ms-0 pb-3 active"
                  data-bs-toggle="pill"
                  href="#tab-1"
                >
                  
                  <i className="fa fa-coffee fa-2x text-garwe"></i>
                  <div className="ps-3">
                    <h6 className="mt-n1 text-garwe mb-0 text-uppercase text-center">
                      Main Menu
                    </h6>
                  </div>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="tab-1" className="tab-pane fade show p-0 active">
                <MenuTab
                  menuName="Starters"
                  menuItems={starters}
                />
                <hr />
                <MenuTab
                  menuName="Starches"
                  menuItems={starches}
                />
                {/*  vegetables,
  meats,
  desserts,
                
                */}
                <hr/>
                <MenuTab menuName="Vegetables" menuItems={vegetables} />
              </div>
              <div id="tab-2" className="tab-pane fade show p-0">
              <MenuTab
                  menuName="Meats"
                  menuItems={meats}
                />
                <hr/>
                <MenuTab
                  menuName="Desserts"
                  menuItems={desserts}
                />
                
              </div>
              <div id="tab-3" className="tab-pane fade show p-0">
                <hr/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

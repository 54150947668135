import React from "react";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import about1 from '../static/img/about-1.jpg';
import about2 from './../static/img/about-2.jpg';
import about3 from './../static/img/about-3.jpg';
import about4 from './../static/img/about-4.jpg';
import { Chef } from "./Chef";

export default function About() {
  return (
    <Container fluid="true" className="container-xxl bg-white p-0">
      <div className="container-xxl py-5 bg-dark hero-header mb-5">
        <div className="container text-center my-5 pt-5 pb-4">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center text-uppercase">
              <li className="breadcrumb-item">
                <Link className="text-garwe" to="/home">Home</Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                About
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* About start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="row g-3">
                <div className="col-6 text-start">
                  <Image
                    className="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.1s"
                    src={about1}
                  />
                </div>
                <div className="col-6 text-start">
                  <Image
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.3s"
                    src={about2}
                    style={{ marginTop: "25%;" }}
                  />
                </div>
                <div className="col-6 text-end">
                  <Image
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={about3}
                  />
                </div>
                <div className="col-6 text-end">
                  <Image
                    className="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.7s"
                    src={about4}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h5 className="section-title ff-secondary text-start text-garwe fw-normal">
                About Us
              </h5>
              <h3 className="mb-4">Welcome to Garwe Restaurant & Events</h3>
              <p className="mb-4">
                A Down-To-Earth, Inexpensive Restaurant with a Flavor Of The Old
                Days that provides services with Warmth and The Taste Of A
                Mother's Cooking. Each dish is made with loving care and served
                with genuine smiles. You can enjoy any combination of our dishes
                according to how you feel day-by-day while staying within a
                budget and always eating nutritious food.
              </p>
              <div className="row g-4 mb-4">
                <div className="col-sm-6">
                  <div className="d-flex align-items-center border-start border-5 border-garwe px-3">
                    <h1
                      className="flex-shrink-0 display-5 text-garwe mb-0"
                      data-toggle="counter-up"
                    >
                      15
                    </h1>
                    <div className="ps-4">
                      <p className="mb-0">Years of</p>
                      <h6 className="text-uppercase mb-0">Experience</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex align-items-center border-start border-5 border-garwe px-3">
                    <h1
                      className="flex-shrink-0 display-5 text-garwe mb-0"
                      data-toggle="counter-up"
                    >
                      50
                    </h1>
                    <div className="ps-4">
                      <p className="mb-0">Popular</p>
                      <h6 className="text-uppercase mb-0">Master Chefs</h6>
                    </div>
                  </div>
                </div>
              </div>
              <a className="btn btn-garwe py-3 px-5 mt-2" href="/#">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* team start */}
      <div className="container-xxl pt-5 pb-3">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="section-title ff-secondary text-center text-garwe fw-normal">Team Members</h5>
                    <h1 className="mb-5">Our Master Chefs</h1>
                </div>
                <div className="row g-6">
                    <Chef name='ELLIOT TSINGANO' designation='Chef'/>
                    <Chef name="PLAXEDES MUTEIWA" designation="Chef"/>
                    <Chef name="RUTENDO MAWENI" designation="Chef"/>
                    <Chef name="TENDAI DANIEL" designation="Chef"/>
                    <Chef name="GRACE MUNODAWAFA" designation="Chef"/>
                </div>
            </div>
        </div>
    </Container>
  );
}

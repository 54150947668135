import { Container } from "react-bootstrap";
import emailjs from "emailjs-com";

export default function Contact() {
  function sendEmail(e: any) {
    e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID!,
        process.env.REACT_APP_TEMPLATE_ID!,
        e.target,
        process.env.REACT_APP_USER_ID,
      )
      .then(
        (result) => {
          window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <Container fluid="true" className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <div className="container-xxl py-5 bg-dark hero-header mb-5">
          <div className="container text-center my-5 pt-5 pb-4">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Contact Us
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <a className="text-garwe" href="/">
                    Home
                  </a>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Contact
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-garwe fw-normal">
              Contact Us
            </h5>
            <h1 className="mb-5">Contact For Any Query</h1>
          </div>
          <div className="row g-4">
            <div className="col-12">
              <div className="row gy-4">
                <div className="col-md-4">
                  <h5 className="section-title ff-secondary fw-normal text-start text-garwe">
                    Booking
                  </h5>
                  <p>
                    <i className="fa fa-envelope-open text-garwe me-2"></i>
                    sales@garwerestaurant.co.zw
                  </p>
                </div>
                <div className="col-md-4">
                  <h5 className="section-title ff-secondary fw-normal text-start text-garwe">
                    General
                  </h5>
                  <p>
                    <i className="fa fa-envelope-open text-garwe me-2"></i>
                    sales@garwerestaurant.co.zw
                  </p>
                </div>
                <div className="col-md-4">
                  <h5 className="section-title ff-secondary fw-normal text-start text-garwe">
                    Technical
                  </h5>
                  <p>
                    <i className="fa fa-envelope-open text-garwe me-2"></i>
                    admin@garwerestaurant.co.zw
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <iframe
                title="Garwe Restaurant & Events"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3798.1998967927966!2d31.073715973988268!3d-17.829261776342676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931a4cd9e8dd5c5%3A0x27cefecfc8bf75e5!2sGarwe%20Restaurant!5e0!3m2!1sen!2szw!4v1725265602615!5m2!1sen!2szw"
                width="550"
                height="450"
                style={{ border: "0" }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                <form onSubmit={sendEmail}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="from_name"
                          placeholder="Your Name"
                        />
                        <label>Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          inputMode="email"
                          className="form-control"
                          id="email"
                          name="from_email"
                          placeholder="Your Email"
                        />
                        <label>Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          inputMode="tel"
                          className="form-control"
                          id="phone"
                          name="from_phone"
                          placeholder="Phone"
                        />
                        <label>Phone</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          name="html_message"
                          style={{ height: "150px" }}
                        ></textarea>
                        <label>Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-garwe w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

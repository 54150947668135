import React from 'react'
import { Container } from 'react-bootstrap'

export default function NotFound() {
  return (
    <Container fluid={true}>
        <h1>404 NotFound</h1>
        <p>The resource you're looking for isn't found</p>
    </Container>
  )
}
